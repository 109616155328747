exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-prematurity-bathing-premature-jsx": () => import("./../../../src/pages/prematurity/bathing-premature.jsx" /* webpackChunkName: "component---src-pages-prematurity-bathing-premature-jsx" */),
  "component---src-pages-prematurity-breast-milk-bank-jsx": () => import("./../../../src/pages/prematurity/breast-milk-bank.jsx" /* webpackChunkName: "component---src-pages-prematurity-breast-milk-bank-jsx" */),
  "component---src-pages-prematurity-breast-milk-pumping-jsx": () => import("./../../../src/pages/prematurity/breast-milk-pumping.jsx" /* webpackChunkName: "component---src-pages-prematurity-breast-milk-pumping-jsx" */),
  "component---src-pages-prematurity-breast-milk-pumping-premature-jsx": () => import("./../../../src/pages/prematurity/breast-milk-pumping-premature.jsx" /* webpackChunkName: "component---src-pages-prematurity-breast-milk-pumping-premature-jsx" */),
  "component---src-pages-prematurity-breastfeeding-science-jsx": () => import("./../../../src/pages/prematurity/breastfeeding-science.jsx" /* webpackChunkName: "component---src-pages-prematurity-breastfeeding-science-jsx" */),
  "component---src-pages-prematurity-cellphones-usage-jsx": () => import("./../../../src/pages/prematurity/cellphones-usage.jsx" /* webpackChunkName: "component---src-pages-prematurity-cellphones-usage-jsx" */),
  "component---src-pages-prematurity-colostrum-extraction-jsx": () => import("./../../../src/pages/prematurity/colostrum-extraction.jsx" /* webpackChunkName: "component---src-pages-prematurity-colostrum-extraction-jsx" */),
  "component---src-pages-prematurity-contact-isolation-jsx": () => import("./../../../src/pages/prematurity/contact-isolation.jsx" /* webpackChunkName: "component---src-pages-prematurity-contact-isolation-jsx" */),
  "component---src-pages-prematurity-developmental-guidance-pre-release-due-jsx": () => import("./../../../src/pages/prematurity/developmental-guidance-pre-release-due.jsx" /* webpackChunkName: "component---src-pages-prematurity-developmental-guidance-pre-release-due-jsx" */),
  "component---src-pages-prematurity-eye-tests-jsx": () => import("./../../../src/pages/prematurity/eye-tests.jsx" /* webpackChunkName: "component---src-pages-prematurity-eye-tests-jsx" */),
  "component---src-pages-prematurity-feeding-bottle-week-34-jsx": () => import("./../../../src/pages/prematurity/feeding-bottle-week34.jsx" /* webpackChunkName: "component---src-pages-prematurity-feeding-bottle-week-34-jsx" */),
  "component---src-pages-prematurity-first-tastes-jsx": () => import("./../../../src/pages/prematurity/first-tastes.jsx" /* webpackChunkName: "component---src-pages-prematurity-first-tastes-jsx" */),
  "component---src-pages-prematurity-g-6-pd-deficiency-jsx": () => import("./../../../src/pages/prematurity/G6PD-deficiency.jsx" /* webpackChunkName: "component---src-pages-prematurity-g-6-pd-deficiency-jsx" */),
  "component---src-pages-prematurity-index-jsx": () => import("./../../../src/pages/prematurity/index.jsx" /* webpackChunkName: "component---src-pages-prematurity-index-jsx" */),
  "component---src-pages-prematurity-on-the-way-home-jsx": () => import("./../../../src/pages/prematurity/on-the-way-home.jsx" /* webpackChunkName: "component---src-pages-prematurity-on-the-way-home-jsx" */),
  "component---src-pages-prematurity-parent-guidance-clinic-1-3-jsx": () => import("./../../../src/pages/prematurity/parent-guidance-clinic-1-3.jsx" /* webpackChunkName: "component---src-pages-prematurity-parent-guidance-clinic-1-3-jsx" */),
  "component---src-pages-prematurity-parent-guidance-clinic-3-6-jsx": () => import("./../../../src/pages/prematurity/parent-guidance-clinic-3-6.jsx" /* webpackChunkName: "component---src-pages-prematurity-parent-guidance-clinic-3-6-jsx" */),
  "component---src-pages-prematurity-parental-guidance-nutrition-jsx": () => import("./../../../src/pages/prematurity/parental-guidance-nutrition.jsx" /* webpackChunkName: "component---src-pages-prematurity-parental-guidance-nutrition-jsx" */),
  "component---src-pages-prematurity-parental-guidance-prevent-falls-jsx": () => import("./../../../src/pages/prematurity/parental-guidance-prevent-falls.jsx" /* webpackChunkName: "component---src-pages-prematurity-parental-guidance-prevent-falls-jsx" */),
  "component---src-pages-prematurity-premature-world-jsx": () => import("./../../../src/pages/prematurity/premature-world.jsx" /* webpackChunkName: "component---src-pages-prematurity-premature-world-jsx" */),
  "component---src-pages-prematurity-recommendations-release-jsx": () => import("./../../../src/pages/prematurity/recommendations-release.jsx" /* webpackChunkName: "component---src-pages-prematurity-recommendations-release-jsx" */),
  "component---src-pages-prematurity-resuscitation-and-choking-jsx": () => import("./../../../src/pages/prematurity/resuscitation-and-choking.jsx" /* webpackChunkName: "component---src-pages-prematurity-resuscitation-and-choking-jsx" */),
  "component---src-pages-prematurity-rsv-vaccine-jsx": () => import("./../../../src/pages/prematurity/rsv-vaccine.jsx" /* webpackChunkName: "component---src-pages-prematurity-rsv-vaccine-jsx" */),
  "component---src-pages-prematurity-tracheostomy-care-jsx": () => import("./../../../src/pages/prematurity/tracheostomy-care.jsx" /* webpackChunkName: "component---src-pages-prematurity-tracheostomy-care-jsx" */),
  "component---src-pages-prematurity-tube-bottle-feeding-readiness-jsx": () => import("./../../../src/pages/prematurity/tube-bottle-feeding-readiness.jsx" /* webpackChunkName: "component---src-pages-prematurity-tube-bottle-feeding-readiness-jsx" */),
  "component---src-pages-surgeries-preparation-index-jsx": () => import("./../../../src/pages/surgeries/preparation/index.jsx" /* webpackChunkName: "component---src-pages-surgeries-preparation-index-jsx" */)
}

